<template>
    <div>
        <div class="text-left">
            <div class="text-5xl">
                Te presentamos nuestras <strong>caracteristicas</strong>
            </div>
            <div>
                Toma el control de tu negocio con una sola aplicacion.
            </div>
        </div>

        <div class="mt-8">
            <div class="flex items-center space-x-12">
                <div v-for="(box, index) in boxes" :key="index">
                    <div class="w-40 h-4 rounded-full border-2 p-4 flex items-center justify-center cursor-pointer"
                        :class="{
                            'bg-gray-100 border-dotted': selectedBox !== index,
                            'bg-white border-solid border-black': selectedBox === index
                        }" @click="selectBox(index)">
                        <span class="text-md font-medium">{{ box.name }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-8">
            <div class="flex flex-row text-xl justify-between">
                <div class=w-1/2>
                    <div>{{ currentBox.description }}</div>
                    <div class="p-4">
                        <ul>
                            <li v-for="app in currentBox.apps" :key="app" class="py-2">
                                <i class="bx bxs-check-circle"></i> &nbsp; {{ app }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class=w-1/2>
                    <img :src="currentBox.image" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { FEATURES } from '../configuration';

export default {
    name: 'Features',
    data() {
        return {
            selectedBox: 0,
            boxes: FEATURES,
        }
    },
    computed: {
        currentBox() {
            return this.boxes[this.selectedBox];
        }
    },
    methods: {
        selectBox(index) {
            this.selectedBox = index;
        },
    },
}
</script>